@import '~bootstrap/scss/bootstrap.scss';
@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-free/scss/solid.scss';
@import '~@fortawesome/fontawesome-free/scss/brands.scss';

@font-face {
  font-family: Esteban;
  src: url("../fonts/Esteban.ttf");
}

@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden; 
  text-overflow: ellipsis;
}

.link-unstyled, .link-unstyled:link  {
  color: inherit;
  text-decoration: inherit;
}