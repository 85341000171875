.logo {
  &-title {
    font-family: Esteban;
    line-height: 1.2em;
  }

  img { 
    &.loader {
      animation-name: spin;
      animation-duration: 600ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;  
      z-index: 100;
    }    
  }

  small  {
    max-width: 15em;
  }
}