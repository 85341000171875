
@import '~bootstrap/scss/bootstrap-reboot.scss';

.posts {
  .card {
    position: relative;
    cursor: pointer;

    &:hover, &.active {
      border-color: $green;
    }

    &:hover {
      .buttons {
        display: block;
      }
    }

    .buttons {
      display: none;
      position: absolute;
      right: 0.7em;
      top: 0.3em;

      a {
        color: $secondary;
        opacity: 0.5;

        &:hover {
          opacity: 0.9;
        } 
      }
    }
  }
}